import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'

// reactstrap components
import { Container, Row, Col, Badge, Progress } from "reactstrap";

// core components

function InfoNews() {
  const {t,i18n} = useTranslation()
  return (
    <>
      <div className="section">
        <Container>
          <h3 className="title">News</h3>
          <div>
            <Row>
              <Col md="12">
                
                <div>
                <Badge color="info" className="mr-1">
                Award
                </Badge>
                  <span>2021/11/11</span>
                  <p><a target="_blank"
                  href="https://hxd-i2jp.com/" style={{ textDecoration: 'none' }}>
                  {t('event.head_hxd')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                    <br></br>
                    <Badge color="info" className="mr-1">
                Award
                </Badge>
                  <span>2021/11/5</span>
                  <p className="text-muted"><a target="_blank"
                  href="https://www.i2jp.net/en/partner.html" style={{ textDecoration: 'none' }}>
                   {t('event.head_i2jp')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                    <br></br>
                <Badge color="info" className="mr-1">
                Information
                </Badge>
                  <span>2021/5/17</span>
                  <p className="text-muted"><a 
                  href="/lanexkmc" style={{ textDecoration: 'none' }}>
                   {t('event.head_kmc')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                    <br></br>
                <Badge color="info" className="mr-1">
                Information
                </Badge>
                  <span>2021/5/17</span>
                  <p className="text-muted"><a target="_blank"
                  href="https://www.jica.go.jp/tohoku/press/ku57pq00000mbntk-att/ku57pq00000mbnu7.pdf" style={{ textDecoration: 'none' }}>
                   {t('event.head_jica')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                    
                    <br></br>
                {/* <Badge color="info" className="mr-1">
                Collaboration
                </Badge>
                  <span>2020/03/17</span>
                  <p className="text-muted"><a 
                  href="/about_content" style={{ textDecoration: 'none' }}>
                   {t('event.head_shiono')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                    <br></br> */}
                <Badge color="info" className="mr-1">
                Partnership
                </Badge>
                  <span>2019/08/30</span>
                  <p className="text-muted"><a 
                  href="/ticad" style={{ textDecoration: 'none' }}>
                   {t('event.head_hm')}
                  </a>
                  </p>
                  <div className="progress-container">
                
                        <Progress>
                        
                        </Progress>
                    </div>
                </div>
                
              </Col>
            </Row>
          </div>
          <div className="space-50"></div>
          
        </Container>
      </div>
    </>
  );
}

export default InfoNews;
