import React from "react";
// reactstrap components
import { Button, Container, Row, Col, Card, CardText, CardBody, CardLink, CardImg,
  CardTitle, CardSubtitle } from "reactstrap";
import { useTranslation } from 'react-i18next'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NewsHeader from "components/Headers/NewsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Link } from "react-router-dom";

// core components

function  LanexShiono() {
  const {t,i18n} = useTranslation()

  return (
    <>
    <IndexNavbar/>
    <div className="wrapper">
    <NewsHeader/>
      <div className="section">
        <Container>
        <Card>
       
        <CardBody>
          <CardTitle className=" h2 mb-0">{t('event.title')}</CardTitle>
          <small className=" text-muted">
          March 17th 2020
          </small>
          <div align="center">
          <CardImg variant="top" style={{width:"30%", height:"30%"}}
              alt="..."
              src={require("assets/img/DrShiono.jpeg")}
              top
            ></CardImg>
            </div>
          <CardText className=" mt-4">
          {t('event.shiono1')}
          <br></br>
          {t('event.shiono2')}
          <br></br>
          {t('event.shiono3')}
          <br></br>
          <br></br>
          <h5>
          {t('event.shionotitle')}
          </h5>
          <a target="_blank" href="https://www.myu.ac.jp/teacher/nursing/siono/" style={{ textDecoration: 'none' }}>
          {t('event.myuwebsite')}
          </a>
          <br></br>
          <a target="_blank" href="https://kerokero-shiono.com/" style={{ textDecoration: 'none' }}>
          {t('event.kerokero')}
          </a>
          <br></br>
          <a target="_blank" href="https://www.midwife-miyagi.net/" style={{ textDecoration: 'none' }}>
          {t('event.midwife_association')}
          </a>
          </CardText>
          <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  to="/what-emch"
                  tag={Link} 
                >
                  {t('event.back_only')}
                </Button>
        </CardBody>
      </Card>
        </Container>
      </div>
      <DarkFooter/>
      </div>
    </>
  );
}

export default  LanexShiono;
