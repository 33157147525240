import React from "react";
import {Helmet} from "react-helmet";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import HospitalHeader from "components/Headers/HospitalHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import PricingDoctor from "views/sections/doctors/PricingDoctor.js";
import HowItWorksDoctor from "views/sections/doctors/HowItWorksDoctor.js";
import FeaturesDoctor from "views/sections/doctors/FeaturesDoctor.js";
import RequirementsDoctor from "views/sections/doctors/RequirementsDoctor.js";
import { useTranslation } from 'react-i18next'


function DoctorPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  
    const updateView = () => {
      //var contentSections = document.getElementsByClassName("cd-section");
    
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("landing-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  const {t,i18n} = useTranslation()


  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <HospitalHeader />
        
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t('navbar.hospital')}</h2>
                <h5 className="description">
                {t('hospital.content1')}
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>
        <HowItWorksDoctor/>
        <FeaturesDoctor/>
        {/* <PricingDoctor/> */}
        <RequirementsDoctor/>
        <DarkFooter />
        <Helmet>
                <meta charSet="utf-8" />
                <title>{t('title.hospital')}</title>
        </Helmet>

      </div>
    </>
  );
}

export default DoctorPage;
