export const COUNTRIES = [

   "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Costa",
        "Cote",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech",
        "Denmark",
        "Djibouti",
        "Dominican",
        "Ecuador",
        "Egypt",
        "Salvador",
        "Equatorial",
        "Estonia",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Guam",
        "Guatemala",
        "Guinea",
        "Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Samoa",
        "Saudi",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "SouthAfrica",
        "SouthKorea",
        "Spain",
        "SriLanka",
        "Sudan",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor",
        "Togo",
        "Tonga",
        "Trinidad",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Uganda",
        "Ukraine",
        "UAE",
        "UK",
        "USA",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe"
    // "Afghanistan",
    // "Albania",
    // "Algeria",
    // "Andorra",
    // "Angola",
    // "Anguilla",
    // "Antigua",
    // "Argentina",
    // "Armenia",
    // "Aruba",
    // "Australia",
    // "Austria",
    // "Azerbaijan",
    // "Bahamas",
    // "Bahrain",
    // "Bangladesh",
    // "Barbados",
    // "Belarus",
    // "Belgium",
    // "Belize",
    // "Benin",
    // "Bermuda",
    // "Bhutan",
    // "Bolivia",
    // "Bosnia",
    // "Botswana",
    // "Brazil",
    // "Brunei",
    // "Bulgaria",
    // "Burkina Faso",
    // "Burundi",
    // "Cambodia",
    // "Cameroon",
    // "Canada",
    // "Cape Verde",
    // "Chad",
    // "Chile",
    // "China",
    // "Colombia",
    // "Congo",
    // "Costa Rica",
    // "Cote D Ivoire",
    // "Croatia",
    // "Cuba",
    // "Cyprus",
    // "Czech Republic",
    // "Denmark",
    // "Djibouti",
    // "Dominican Republic",
    // "Ecuador",
    // "Egypt",
    // "El Salvador",
    // "Equatorial Guinea",
    // "Estonia",
    // "Ethiopia",
    // "Fiji",
    // "Finland",
    // "France",
    // "Gabon",
    // "Gambia",
    // "Georgia",
    // "Germany",
    // "Ghana",
    // "Greece",
    // "Guam",
    // "Guatemala",
    // "Guinea",
    // "Guinea Bissau",
    // "Guyana",
    // "Haiti",
    // "Honduras",
    // "Hungary",
    // "Iceland",
    // "India",
    // "Indonesia",
    // "Iran",
    // "Iraq",
    // "Ireland",
    // "Israel",
    // "Italy",
    // "Jamaica",
    // "Japan",
    // "Jordan",
    // "Kazakhstan",
    // "Kenya",
    // "Kuwait",
    // "Kyrgyz Republic",
    // "Laos",
    // "Latvia",
    // "Lebanon",
    // "Lesotho",
    // "Liberia",
    // "Libya",
    // "Liechtenstein",
    // "Lithuania",
    // "Luxembourg",
    // "Macau",
    // "Macedonia",
    // "Madagascar",
    // "Malawi",
    // "Malaysia",
    // "Maldives",
    // "Mali",
    // "Malta",
    // "Mauritania",
    // "Mauritius",
    // "Mexico",
    // "Moldova",
    // "Monaco",
    // "Mongolia",
    // "Montenegro",
    // "Morocco",
    // "Mozambique",
    // "Namibia",
    // "Nepal",
    // "Netherlands",
    // "New Zealand",
    // "Nicaragua",
    // "Niger",
    // "Nigeria",
    // "Norway",
    // "Oman",
    // "Pakistan",
    // "Palestine",
    // "Panama",
    // "Papua New Guinea",
    // "Paraguay",
    // "Peru",
    // "Philippines",
    // "Poland",
    // "Portugal",
    // "Puerto Rico",
    // "Qatar",
    // "Reunion",
    // "Romania",
    // "Russia",
    // "Rwanda",
    // "Samoa",
    // "Saudi Arabia",
    // "Senegal",
    // "Serbia",
    // "Seychelles",
    // "Sierra Leone",
    // "Singapore",
    // "Slovakia",
    // "Slovenia",
    // "South Africa",
    // "South Korea",
    // "Spain",
    // "Sri Lanka",
    // "Sudan",
    // "Swaziland",
    // "Sweden",
    // "Switzerland",
    // "Syria",
    // "Taiwan",
    // "Tajikistan",
    // "Tanzania",
    // "Thailand",
    // "Timor L'Este",
    // "Togo",
    // "Tonga",
    // "Trinidad & Tobago",
    // "Tunisia",
    // "Turkey",
    // "Turkmenistan",
    // "Uganda",
    // "Ukraine",
    // "United Arab Emirates",
    // "United Kingdom",
    // "United States",
    // "Uruguay",
    // "Uzbekistan",
    // "Venezuela",
    // "Vietnam",
    // "Yemen",
    // "Zambia",
    // "Zimbabwe",
    
  ];