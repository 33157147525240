import React from 'react';
import {Button, Card, CardBody, CardTitle, Container, Row, Col} from "reactstrap";
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

function PricingMother() {
  const {t,i18n} = useTranslation()

    return (
        <>
        <div className="section section-pricing cd-section" id="pricing-mother">
                <div className="pricing-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{t('navbar.pricing')}</h2>
                <h5 className="description">
                {t('mother.content3')}                </h5>
                <br />
               
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/bg5.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    
                    <CardTitle tag="h4">
                      <b>{t('mother.pricing1')} </b>
                        
                        
                    </CardTitle>
                    <ul>
                      <li>
                      {t('mother.pricing2')}
                      </li>
                      <li>
                      {t('mother.pricing3')} 
                      </li>
                      <li>
                      {t('mother.pricing4')}
                      </li>
                      
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      to="/contact" tag={Link} 
                    >
                      {t('mother.started')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </Container>
        </div>
        
        
      </div>
      </>
    );
}

export default PricingMother
