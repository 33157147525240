import React from "react";
import { Link } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { useTranslation } from 'react-i18next'


function PricingDoctor() {
  const {t,i18n} = useTranslation()

  
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing-doctor">
                <div className="pricing-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{t('navbar.pricing')}</h2>
                <h5 className="description">
                {t('hospital.content3')}                
                </h5>
                <br />
               
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/bg5.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">Lite</h6>
                    <CardTitle tag="h4">
                      <b>{t('hospital.setting')}</b>
                        <small>{t('hospital.settinglite')}</small>
                        <br/>
                        <b>{t('hospital.operating')}</b>
                        <small>{t('hospital.operatinglite')}</small>
                         <small>/mo</small>
                    </CardTitle>
                    <ul>
                      <li>
                        <b>{t('hospital.lite1')}</b>
                      </li>
                      <li>
                        <b>{t('hospital.lite2')}</b> 
                      </li>
                      <li>
                        <b>{t('hospital.lite3')}</b>
                      </li>
                      
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      to="/contact" tag={Link} 
                      // href="#pablo"
                      // onClick={e => e.preventDefault()}
                    >
                      {t('hospital.contact')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/bg5.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category"> {t('hospital.basic')}</h6>
                    <CardTitle tag="h4">
                      <b>{t('hospital.setting')}</b>
                        <small>{t('hospital.settingbasic')}</small>
                         <br/>
                        <b>{t('hospital.operating')}</b>
                        <small>{t('hospital.operatingbasic')}</small>
                        <small>/mo</small>
                    </CardTitle>
                    <ul>
                      <li>
                        <b>{t('hospital.basic1')}</b> 
                      </li>
                      <li>
                        <b>{t('hospital.basic2')}</b> 
                      </li>
                      <li>
                        <b>{t('hospital.basic3')}</b> 
                      </li>
                      
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      to="/contact" tag={Link} 
                      // href="#pablo"
                      // onClick={e => e.preventDefault()}
                    >
                      {t('hospital.contact')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/bg5.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category"> {t('hospital.premium')}</h6>
                    <CardTitle tag="h4">
                      <b>{t('hospital.setting')} </b>
                        <small>{t('hospital.settingpremium')}</small>
                         <br/>
                        <b>{t('hospital.operating')} </b>
                        <small>{t('hospital.operatingpremium')}</small>
                         <small>/mo</small>
                    </CardTitle>
                    
                    <ul>
                    
                      <li>
                        <b>{t('hospital.premium1')}</b> 
                      </li>
                      <li>
                        <b>{t('hospital.premium2')}</b> 
                      </li>
                      <li>
                        <b>{t('hospital.premium3')}</b> 
                      </li>
                      
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      to="/contact" tag={Link} 
                      // onClick={e => e.preventDefault()}
                    >
                      {t('hospital.contact')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        
        
      </div>
    </>
  );
}

export default PricingDoctor;
