import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import {useTranslation} from "react-i18next";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t, i18n } = useTranslation()

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="#"
              target="_blank"
              id="navbar-brand"
            >
              <img
              alt="..."
              className="n-logo"
              src={require("assets/img/emchLogo.svg")}
            ></img>
              {/* Electronic Maternal and Child Health */}
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Created by LANEX Corporation
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem>
                <NavLink
                to="/index"
                outline
                tag={Link}
                >
                  <p>{t('navbar.home')}</p>
                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink
                to="/what-emch"
                outline
                tag={Link}
                >
                  <p>{t('navbar.what')}</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons business_bank"></i>
                  <p>{t('navbar.hospital')}</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                  to="/doctor-page#works-doctor"
                  tag={Link}
                  >
                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                    {t('navbar.how')}
                  </DropdownItem>
                  <DropdownItem
                   to="/doctor-page#features-doctor"
                   tag={Link}
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    {t('navbar.features')}
                  </DropdownItem>
                  {/* <DropdownItem
                    to="/doctor-page#pricing-doctor"
                    tag={Link}
                  >
                    <i className="now-ui-icons business_money-coins"></i>
                    {t('navbar.pricing')}
                  </DropdownItem> */}
                  <DropdownItem
                  to="/doctor-page#requirements-doctor" tag={Link}
                   
                  >
                    <i className="now-ui-icons gestures_tap-01"></i>
                    {t('navbar.requirements')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons users_single-02"></i>
                  <p>{t('navbar.mothers')}</p>
                </DropdownToggle>
                <DropdownMenu>
                <DropdownItem to="/mother-page#works-mother" tag={Link}>
                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                    {t('navbar.how')}
                  </DropdownItem>
                  <DropdownItem
                    to="/mother-page#features-mother" tag={Link}
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    {t('navbar.features')}
                  </DropdownItem>
                  {/* <DropdownItem
                    to="/mother-page#pricing-mother" tag={Link}
                  >
                    <i className="now-ui-icons business_money-coins"></i>
                    {t('navbar.pricing')}
                  </DropdownItem> */}
                  <DropdownItem
                  to="/mother-page#requirements-mother" tag={Link}
                  >
                    <i className="now-ui-icons gestures_tap-01"></i>
                    {t('navbar.requirements')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> 
              {/* <NavItem>
                <NavLink
                to="/news"
                outline
                tag={Link}
                >
                  <p>{t('navbar.news')}</p>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  href="#"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                {t('navbar.follow')} 
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                {t('navbar.like')} 
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                {t('navbar.follow')} 
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#"
                  // target="_blank"
                  id="en-tooltip"
                  onClick={() => {i18n.changeLanguage('en')
                                  window.location.reload(false)                  
                }}
                >
                  <img
                  alt="..."
                  src={require("assets/img/flags/GB.png")}
                ></img>
                </NavLink>
                <UncontrolledTooltip target="#en-tooltip">
                  English
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  // target="_blank"
                  id="jp-tooltip"
                  onClick={() => {i18n.changeLanguage('ja')
                                  window.location.reload(false)                  
                }}
                >
                  <img
                  alt="..."
                  src={require("assets/img/flags/JP.png")}
                ></img>
                </NavLink>
                <UncontrolledTooltip target="#jp-tooltip">
                  Japanese
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  // target="_blank"
                  id="fr-tooltip"
                  onClick={() => {i18n.changeLanguage('fr')
                                  window.location.reload(false)                  
                }}
                >
                  <img
                  alt="..."
                  src={require("assets/img/flags/FR.png")}
                ></img>
                </NavLink>
                <UncontrolledTooltip target="#fr-tooltip">
                  French
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
