import React from 'react';
import {
    Container,
    Row,
    Col
  } from "reactstrap";
  import { useTranslation } from 'react-i18next'

function HowItWorksDoctor() {
  const {t,i18n} = useTranslation()

    return (
        <div className="section section-team text-center" id="works-doctor">
          <Container>
            <h2 className="title">{t('navbar.how')}</h2>
            <div className="team">
              <Row>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/pc.png")}
                    ></img>
                    <h4 className="title">{t('hospital.title1')}</h4>
                    <p className="description">
                    {t('hospital.subtitle1')}
                    </p>
                    
                  </div>
                </Col>
                
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/key.png")}
                    ></img>
                    <h4 className="title">{t('hospital.title2')}</h4>
                    <p className="description">
                    {t('hospital.subtitle2')}                    </p>
                    
                  </div>
                </Col>
                
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/data_m.png")}
                    ></img>
                    <h4 className="title">{t('hospital.title3')}</h4>
                    <p className="description">
                    {t('hospital.subtitle3')}                    
                    </p>
                    
                  </div>
                
                
                </Col>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/baby.png")}
                    ></img>
                    <h4 className="title">{t('hospital.title4')}</h4>
                    <p className="description">
                    {t('hospital.subtitle4')}
                     </p>
                    
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    )
}

export default HowItWorksDoctor
