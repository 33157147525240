import React from "react";
import {Helmet} from "react-helmet";

// reactstrap components
import { Button, Container, Row, Col, Card, CardText, CardBody, CardLink, CardImg,
  CardTitle, CardSubtitle } from "reactstrap";
import { useTranslation } from 'react-i18next'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NewsHeader from "components/Headers/NewsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Link } from "react-router-dom";

// core components

function News() {
  const {t,i18n} = useTranslation()

  return (
    <>
    <IndexNavbar/>
    <div className="wrapper">
    <NewsHeader/>
      <div className="section">
        <Container>
          <Row>
          <Col lg="4" md="16">
          <Card className="card-pricing">
            <CardImg style={{width:"100%", height:"40%"}}
              alt="..."
              src={require("assets/img/lanexhm.jpeg")}
              top
            ></CardImg>
              <CardBody>
                <CardTitle className=" h2 mb-0">TICAD 7</CardTitle>
                <CardSubtitle tag="h4" className="mb-2 text-muted">Partnership agreement </CardSubtitle>
                <small className=" text-muted">
                  August 30th 2019
                </small>
                <CardText className="mt-3" style={{height:"150px"}}>
                {t('event.senegal')}
                </CardText>
                <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  to="/ticad"
                  tag={Link} 
                >
                  {t('event.read_article')}
                </Button>
              </CardBody>
           </Card>
          </Col>
          <Col lg="4" md="16">
          <Card className="card-pricing">
            <CardImg style={{width:"100%", height:"40%"}}
              alt="..."
              src={require("assets/img/jica-logo.png")}
              top
            ></CardImg>
              <CardBody>
                <CardTitle className=" h2 mb-0">Information</CardTitle>
                <CardSubtitle tag="h4" className="mb-2 text-muted">JICA’s SME/SDG Project </CardSubtitle>
                <small className=" text-muted">
                   April 15th 2021
                </small>
                <CardText className="mt-3" style={{height:"150px"}}>
                {t('event.jica')}
                </CardText>
                <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  target="_blank"
                  href="https://www.jica.go.jp/tohoku/press/ku57pq00000mbntk-att/ku57pq00000mbnu7.pdf"
                >
                  {t('event.read_article')}
                </Button>
              </CardBody>
           </Card>
          </Col>
          <Col lg="4" md="16">
          <Card className="card-pricing">
            <CardImg 
              alt="..."
              src={require("assets/img/lanex.jpg")}
              top
            ></CardImg>
              <CardBody>
                <CardTitle className=" h2 mb-0">Information</CardTitle>
                <CardSubtitle tag="h4" className="mb-2 text-muted">LANEX - KMC</CardSubtitle>
                <small className=" text-muted">
                April 15th 2021
                </small>
                <CardText className="mt-3" style={{height:"150px"}}>
                {t('event.kmc_intro')}
                </CardText>
                <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  to="/lanexkmc"
                  tag={Link} 
                >
                  {t('event.read_article')}
                </Button>
              </CardBody>
           </Card>
          </Col>
          <Col lg="4" md="16">
          <Card className="card-pricing">
            <CardImg 
              alt="..."
              src={require("assets/img/i2jp.png")}
              top
            ></CardImg>
              <CardBody>
                <CardTitle className=" h2 mb-0">Award</CardTitle>
                <CardSubtitle tag="h4" className="mb-2 text-muted">Healthcare x Digital 2021</CardSubtitle>
                <small className=" text-muted">
                November 12th 2021
                </small>
                <CardText className="mt-3" style={{height:"150px"}}>
                {t('event.head_i2jp')}
                </CardText>
                <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  target="_blank"
                  href="https://hxd-i2jp.com/"
                >
                  {t('event.read_article')}
                </Button>
              </CardBody>
           </Card>
           </Col>
           <Col lg="4" md="16">
           <Card className="card-pricing">
            <CardImg 
              alt="..."
             src={require("assets/img/DrShiono.jpeg")}
              top
            ></CardImg>
              <CardBody >
                <CardTitle className=" h2 mb-0">Collaboration</CardTitle>
                <CardSubtitle tag="h4" className="mb-2 text-muted"></CardSubtitle>
                <small className=" text-muted">
                March 17th 2020
                </small>
                <CardText className="mt-3" style={{height:"150px"}}>
                {t('event.head_shiono')}
                </CardText>
                <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  to="/about_content"
                  tag={Link} 
                >
                  {t('event.read_article')}
                </Button>
              </CardBody>
           </Card>
          </Col>
          
        </Row>
        </Container>
        <Helmet>
          <meta charSet="utf-8" />
                <title>EMCH News and Press releases</title>
          </Helmet>
      </div>
      <DarkFooter/>
      </div>
    </>
  );
}

export default News;
