import React from "react";
// reactstrap components
import { Button, Container, Row, Col, Card, CardText, CardBody, CardLink, CardImg,
  CardTitle, CardSubtitle } from "reactstrap";
import { useTranslation } from 'react-i18next'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHeader from "components/Headers/PrivacyHeader";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Link } from "react-router-dom";

// core components

function Privacy() {
  const {t,i18n} = useTranslation()

  return (
    <>
    <IndexNavbar/>
    <div className="wrapper">
    <ContactHeader/>
      <div className="section">
        <Container>
        <Card>
       
        <CardBody>
          <CardTitle className="h2 mb-0 text-center">{t('privacy.title')}</CardTitle>
          {t('privacy.updated')}
          <br></br>
          
          
          <CardText>
          <p>
          {t('privacy.p1')}
            </p>
          <CardSubtitle className="h4"> {t('privacy.subtitle1')}</CardSubtitle>
          <p>{t('privacy.p2')}</p>
          <p>{t('privacy.p3')}</p>
          <br></br>
          <p>
              <li>{t('privacy.p4')}</li>
              <li>{t('privacy.p5')}</li>
              <li>{t('privacy.p6')}</li>
              <li>{t('privacy.p7')}</li>
              <li>{t('privacy.p8')}</li>
              <li>{t('privacy.p9')}</li>
              <li>{t('privacy.p10')}</li>
              <li>{t('privacy.p11')}</li>
              <li>{t('privacy.p12')}</li>
              <li>{t('privacy.p13')}</li>
              <li>{t('privacy.p14')}</li>
          </p>
          <CardSubtitle className="h4">{t('privacy.subtitle4')}</CardSubtitle>
          <CardSubtitle className="h4">{t('privacy.subtitle5')}</CardSubtitle>
          <b className="h4">{t('privacy.subtitle6')}</b>
            <br></br>  
            <p>
            {t('privacy.p15')}
            </p>    
            <p>
                <li>{t('privacy.p16')}</li>
                <li>{t('privacy.p17')}</li>
                <li>{t('privacy.p18')}</li>
                <li>{t('privacy.addr')}</li>
                <li>{t('privacy.p19')}</li>
                <li>{t('privacy.p20')}</li>
            </p>
            <b className="h4">{t('privacy.subtitle7')}</b>
            <br></br> 
            <p>{t('privacy.p21')}
            </p>
            <br></br>  
            <b className="h4">{t('privacy.subtitle8')}</b>
            <br></br>
            <p>{t('privacy.p22')}
            </p>
            <p>
                <li>{t('privacy.p23')}</li>
                <li>{t('privacy.p24')}</li>
                <li>{t('privacy.p25')}</li>
                <li>{t('privacy.p26')}</li>
            </p>
            <CardSubtitle className="h4">{t('privacy.subtitle9')}</CardSubtitle>
            <p>{t('privacy.p27')}</p>
            <p>
                <li>{t('privacy.p28')}</li>
                <li>{t('privacy.p29')}</li>
                <li>{t('privacy.p30')}</li>
                <li>{t('privacy.p31')}</li>
                <li>{t('privacy.p32')}</li>
                <li>{t('privacy.p33')}</li>
                <li>{t('privacy.p34')}</li>
                <li>{t('privacy.p35')}</li>
                <li>{t('privacy.p36')}</li>
                <li>{t('privacy.p37')}</li>
            </p>
            <p>{t('privacy.p40')}</p>
            <p>
                <li>{t('privacy.p41')}</li>
                <li>{t('privacy.p42')}</li>
                <li>{t('privacy.p43')}</li>
                <li>{t('privacy.p44')}</li>
            </p>
            <CardSubtitle className="h4">{t('privacy.subtitle10')}</CardSubtitle>
            <p>{t('privacy.p45')}</p>
            <p>{t('privacy.p46')}</p>
            <CardSubtitle className="h4">{t('privacy.subtitle11')}</CardSubtitle>
            <p>{t('privacy.p50')}</p>
            <p>{t('privacy.p51')}</p>
            <p>{t('privacy.p52')}</p>
            <CardSubtitle className="h4">{t('privacy.subtitle12')}</CardSubtitle>
            <b className="h4">{t('privacy.p53')}</b>
            <br></br>
            <p>{t('privacy.p54')}</p>
            <b className="h4">{t('privacy.p55')}</b>
            <br></br>
            <p>{t('privacy.p56')}</p>
            <p>
                <li>{t('privacy.p57')}</li>
                <li>{t('privacy.p58')}</li>
                <li>{t('privacy.p59')}</li>
                <li>{t('privacy.p60')}</li>
                <li>{t('privacy.p61')}</li>
            </p>
            <CardSubtitle className="h4">{t('privacy.subtitle13')}</CardSubtitle>
            <p>{t('privacy.p62')}</p>
            <CardSubtitle className="h4">{t('privacy.subtitle14')}</CardSubtitle>
            <p>{t('privacy.p63')}</p>
            <CardSubtitle className="h4">{t('privacy.subtitle15')}</CardSubtitle>
            <p>{t('privacy.p64')}</p>
            <p>{t('privacy.p65')}</p>
            <p>{t('privacy.p66')}</p>
            <CardSubtitle className="h4">{t('privacy.subtitle16')}</CardSubtitle>
            {t('privacy.p67')}
          </CardText>
        </CardBody>
      </Card>
        </Container>
      </div>
      <DarkFooter/>
      </div>
    </>
  );
}

export default Privacy;
