import React from 'react';
import { Container, Row, Col} from "reactstrap";
import { useTranslation } from 'react-i18next'

function RequirementsMother() {
  const {t,i18n} = useTranslation()

    return (
        <div className="section section-contact-us text-center" id="requirements-mother">
          <Container>
            <h2 className="title">{t('navbar.requirements')}</h2>
            <p className="description">{t('mother.content4')}</p>
            <Row>
              <Col className="mb-4 text-center" md="6">
                <h4 className="title">{t('mother.android')}</h4>
                {t('mother.spec1')}
                
                

              </Col>
              <Col className="mb-4 text-center" md="6">
                <h4 className="title">{t('mother.ios')}</h4>
                {t('mother.spec2')}
                
              </Col>
            </Row>
          </Container>
        </div>
    )
}

export default RequirementsMother
