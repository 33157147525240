import React from "react";
// reactstrap components
import { Button, Container, Row, Col, Card, CardText, CardBody, CardLink, CardImg,
  CardTitle, CardSubtitle } from "reactstrap";
import { useTranslation } from 'react-i18next'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NewsHeader from "components/Headers/NewsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Link } from "react-router-dom";

// core components

function  Ticad() {
  const {t,i18n} = useTranslation()

  return (
    <>
    <IndexNavbar/>
    <div className="wrapper">
    <NewsHeader/>
      <div className="section">
        <Container>
        <Card>
        
        <CardBody>
          <CardTitle className=" h2 mb-0">{t('article.ticad_title')}</CardTitle>
          <small className=" text-muted">
          August 30th 2019
          </small>
          <br></br>
          <div align="center">
          <CardImg variant="top" style={{width:"50%", height:"40%"}}
              alt="..."
              src={require("assets/img/lanexhm.jpeg")}
              top
            ></CardImg>
            </div>
          <CardText className=" mt-4">
          {t('event.senega_lanex_part1')}
          <br></br>
          <br></br>
          {t('event.senega_lanex_part2')}
          <br></br>
          <br></br>
          {t('event.senega_lanex_part3')}
          </CardText>
          <Button
                  className="btn-round mr-1"
                  color="info"
                  size="lg"
                  to="/home"
                  tag={Link} 
                >
                  {t('event.back')}
                </Button>
        </CardBody>
      </Card>
        </Container>
      </div>
      <DarkFooter/>
      </div>
    </>
  );
}

export default  Ticad;
