import React from "react";
import {Helmet} from "react-helmet";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import MotherHeader from "components/Headers/MotherHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import PricingMother from "views/sections/mothers/PricingMother";
import FeaturesMother from "views/sections/mothers/FeaturesMother";
import RequirementsMother from "views/sections/mothers/RequirementsMother";
import HowitWorksMother from "views/sections/mothers/HowitWorksMother";
import { useTranslation } from 'react-i18next';


function MotherPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }

    const updateView =()=> {
      //var contentSections = document.getElementsByClassName("cd-section");

    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("landing-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  const {t,i18n} = useTranslation()

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <MotherHeader />
        
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t('navbar.mothers')}</h2>
                <h5 className="description">
                {t('mother.content1')}
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>
        <HowitWorksMother/>
        <FeaturesMother/>
        {/* <PricingMother/> */}
        <RequirementsMother/>
        <DarkFooter />
        <Helmet>
                <meta charSet="utf-8" />
                <title>EMCH for Pregnant Mother</title>
        </Helmet>
      </div>
    </>
  );
}

export default MotherPage;
