import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from 'react-i18next'



// core components

function ContactHeader() {
  let newHeader = React.createRef();

  const {t,i18n} = useTranslation()

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        newHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header clear-filter page-header-small"
        filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg_image.jpeg") + ")",
          }}
          ref={newHeader}
        ></div>
        <div className="content-center">
          <Container>
            <h2 className="title">{t('contact.inquiry1')}</h2>
            <div className="text-center">
            <h3 className="h3 mb-0">
              {t('contact.inquiry2')}
          
          </h3>
            </div>
            
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactHeader;
