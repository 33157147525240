import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Helmet } from "react-helmet";
import {useTranslation} from "react-i18next";


// sections for this page
import ContentSection from "./views/index-sections/ContentSection";
import InfoNews from "views/index-sections/InfoNews.js";

function App() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const { t, i18n } = useTranslation()
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <ContentSection />
          <InfoNews/>
          <Helmet>
          <meta charSet="utf-8" />
                <title>{t('title.home')}</title>
          </Helmet>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default App;
