import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next'


// core components

function ContentSection() {
  const {t,i18n} = useTranslation()

  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">{t('welcome.home1')}</h2>
              <h5 className="description">
              {t('welcome.home2')}
              </h5>
              
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
              <img
                  alt="..."
                  src={require("assets/img/content3.png")}
                ></img>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">{t('welcome.home3')}</h2>
              <h5 className="description">
              {t('welcome.home4')}
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
              <img
                  alt="..."
                  src={require("assets/img/content_1.png")}
                ></img>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">{t('welcome.home5')}</h2>
              <h5 className="description">
              {t('welcome.home6')}
              </h5>
            </Col>
            <Col lg="6" md="10">
              <div className="icons-container">
              <img
                  alt="..."
                  src={require("assets/img/content_4.png")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContentSection;
