import React, {useState} from "react";
import swal from 'sweetalert';
import {useTranslation} from "react-i18next";
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText
} from "reactstrap";
import Axios from "axios";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/Headers/PageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Portfolio from "views/index-sections/Portfolio.js";
import { COUNTRIES } from "exports";
import { Helmet } from "react-helmet";


function WhatEmch() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [secondFocus, setSecondFocus] = React.useState(false);
  const [thirdFocus, setThirdFocus] = React.useState(false);
  const [fourthFocus, setFourthFocus] = React.useState(false);
  const [fifthFocus, setFifthFocus] = React.useState(false);
  const [sixthFocus, setSixthFocus] = React.useState(false);
  const [firstNameErr, setFirstNameErr] = React.useState({});
  const [familyNameErr, setFamilyNameErr] = React.useState({});
  const [emailErr, setEmailErr] = React.useState({});
  const [countryErr, setCountryErr] = React.useState({});
  const { t, i18n } = useTranslation()

  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState('');

  const submit = () => {
    const isValid = formValidation();

    if(isValid){
    if (firstName && familyName && email && company && title && country){
      const serviceId = 'service_yorbhs7';
      const templateId = 'template_nt0f8qa';
      const userId = 'user_TejEPUgXMihndZYpxxULC';
      const templateParams = {
        firstName,
        familyName,
        email,
        company,
        title,
        country
      };

      emailjs.send(serviceId, templateId, templateParams, userId)
             .then(response=>{if (response.status=200) {
                        swal({
                          title: t('alert.title'),
                          text: t('alert.text'),
                          icon: "success",
                         // timer: 2000,
                          button: t('alert.button')
                        })
                        
                        console.log(response);
                      } else {
                        swal({
                          title: t('alert.errortitle'),
                          text: t('alert.errortext'),
                          icon: "error",
                          //timer: 2000,
                          button: t('alert.button')
                        })
                        console.log("There are errors in your request");
                      }
                    })

      setFirstName('');
      setFamilyName('');
      setEmail('');
      setCompany('');
      setTitle('');
      setCountry('');
    }
   } 
  }

  const isValidEmail = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

  // const initialState = {firstName: "", familyName: "", email: "", company: "", title: "", country: ""};
  // const [newRequest, setNewRequest] = React.useState(initialState);

  //const { firstName, familyName, email, company, title, country} = newRequest;

  

  // const handleInputChange = e => {
  //   setNewRequest({ ...newRequest, [e.target.name]: e.target.value});
  // };
  // const BASE_URL =　process.env.REACT_APP_API_SERVER;
  // const requestDemo = e => {
    

  //   const isValid = formValidation();

  //   if(isValid){

  //   Axios({
  //     method: "POST",
  //     // url: process.env.REACT_APP_BASE_URL + '/send/',
  //     url: BASE_URL + '/send/',
  //     data: { firstName, familyName, email, company, title, country },
  //     headers: {        
  //       "Content-Type": "application/json",
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //   }).then(res => {
  //     if (res.data.msg === "suc") {
  //       swal({
  //         title: "Done!",
  //         text: "Your request has been sent",
  //         icon: "success",
  //         timer: 2000,
  //         button: false
  //       })
        
  //       console.log("Your request has been sent");
  //       setNewRequest(initialState);
  //     } else {
  //       swal({
  //         title: "Error!",
  //         text: "Sorry there was a problem with your request. Please try again.",
  //         icon: "error",
  //         timer: 2000,
  //         button: false
  //       })
  //       console.log("There are errors in your request");
  //     }
  //   })
  // }
  // };  

  const formValidation = () => {
    const firstNameErr = {};
    const familyNameErr = {};
    const emailErr ={};
    const countryErr = {};
    let isValid = true;
    const regex = /\S+@\S+\.\S+/;

    if (firstName === "") {
      firstNameErr.firstNameRequired = t('required.firstname');
      isValid = false;
    }

    if (familyName === ""){
      familyNameErr.familyNameRequired = t('required.familyname');
      isValid = false;
    }

    if (!email) {
      emailErr.emailRequired = t('required.email');
      isValid = false;  
    } else if (!regex.test(email)) {
      emailErr.emailInvalid = t('required.invalid');
      isValid = false;
    } 

    if (country === ""){
      countryErr.countryRequired = t('required.country');
      isValid = false;
    }



    setFirstNameErr(firstNameErr);
    setFamilyNameErr(familyNameErr);
    setEmailErr(emailErr);
    setCountryErr(countryErr);
    return isValid;
  }

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <PageHeader />
        
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t('navbar.what')}</h2>
                <h5 className="description">
                {t('whatemch.content1')}
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/emch_phone.jpg") + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      {t('whatemch.whocontent')}
                       
                      {/* <small>-WHO</small> */}
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/emch_phone.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/emch_desk2.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    {t('whatemch.content2')}
                  </h3>
                  <p>
                  {t('whatemch.content3')}
                  </p>
                  <p>
                  {t('whatemch.content4')}

                  </p>
                  <p>
                  {t('whatemch.content5')}
                  </p>
                  <p className="section-about-us">
                    <li>{t('whatemch.item1')}</li>
                    <li>{t('whatemch.item2')}</li>
                    <li>{t('whatemch.item3')}</li>
                    <li>{t('whatemch.item4')}</li>
                    <li>{t('whatemch.item5')}</li>
                    <li>{t('whatemch.item8')}</li>
                  </p>
                </Col>
              </Row>
            </div>

            <div className="features-4">
            <Row>
            <Col lg="6" md="12">
              <h2 className="title">{t('event.title')}</h2>
              <h5 className="description">
              {t('event.head_shiono')}
              <br></br>
              <Button
                  className="btn-round mr-1 btn-sm"
                  color="info"
                  size="lg"
                  to="/about_content"
                  tag={Link} 
                >
                  {t('event.read_article')}
                </Button>
              </h5>
              
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
              <img
                  alt="..."
                  src={require("assets/img/DrShiono.jpeg")}
                ></img>
              </div>
            </Col>
          </Row>
        </div>
       
          </Container>
        </div>
        
        <div className="section section-contact-us text-center" id="request-demo">
          <Container>
            <h2 className="title">{t('whatemch.request1')}</h2>
            <p className="description">{t('whatemch.request2')}</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
              <div>
                {Object.keys(firstNameErr).map((key) => {
                return <FormText className="text-muted" color="primary">
                  {firstNameErr[key]}
                  </FormText>
              })}
              </div>
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                 
             
                  <Input
                    name="firstName"
                    //onChange = {handleInputChange}
                    onChange = { e => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder={t('whatemch.firstname')}
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input> 
                </InputGroup>
                
                <div>
                {Object.keys(familyNameErr).map((key) => {
                return <FormText className="text-muted" color="primary">
                  {familyNameErr[key]}
                  </FormText>
              })}
              </div>
                <InputGroup
                  className={
                    "input-lg" + (secondFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="familyName"
                    //onChange ={handleInputChange}
                    onChange = { e => setFamilyName(e.target.value)}
                    value={familyName}
                    placeholder={t('whatemch.familyname')}
                    type="text"
                    onFocus={() => setSecondFocus(true)}
                    onBlur={() => setSecondFocus(false)}
                  ></Input>
                </InputGroup>

                <div>
                {Object.keys(emailErr).map((key) => {
                return <FormText className="text-muted" color="primary">
                  {emailErr[key]}
                  </FormText>
              })}
                </div>
                <InputGroup
                  className={
                    "input-lg" + (thirdFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="email"
                  //  onChange ={handleInputChange}
                    onChange = { e => setEmail(e.target.value)}
                    value={email}
                    placeholder={t('whatemch.email')}
                    type="text"
                    onFocus={() => setThirdFocus(true)}
                    onBlur={() => setThirdFocus(false)}
                  ></Input>
                 
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (fourthFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_bank"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="company"
                   // onChange ={handleInputChange}
                    onChange = { e => setCompany(e.target.value)}
                    value={company}
                    placeholder={t('whatemch.company')}
                    type="text"
                    onFocus={() => setFourthFocus(true)}
                    onBlur={() => setFourthFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (fifthFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_badge"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="title"
                   // onChange ={handleInputChange}
                    onChange = { e => setTitle(e.target.value)}
                    value={title}
                    placeholder={t('whatemch.job')}
                    type="text"
                    onFocus={() => setFifthFocus(true)}
                    onBlur={() => setFifthFocus(false)}
                  ></Input>
                </InputGroup>
                <div>
                {Object.keys(countryErr).map((key) => {
                return <FormText className="text-muted" color="primary">
                  {countryErr[key]}
                  </FormText>
              })}
                </div>
                <InputGroup
                  className={
                    "input-lg" + (sixthFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons location_world"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="country"
                   // onChange ={handleInputChange}
                    onChange = { e => setCountry(e.target.value)}
                    value={country}
                    type="select"
                    onFocus={() => setSixthFocus(true)}
                    onBlur={() => setSixthFocus(false)}
                  >
                    <option selected="">{t('whatemch.choose')}</option>
                    {COUNTRIES.map(c => (
                          <option key={c} value={c} label={t(`countries.${c}`)}>
                            {c}
                          </option>
                        ))}
                    
                  </Input>
                </InputGroup>
                <div className="send-button">
                  <Button
                  type="sunmit"
                    block
                    className="btn-round"
                    color="info"
                    // href="#pablo"
                    size="lg"
                    onClick={submit}
                  >
                    {t('common.request')}
                  </Button>
                </div>
              </Col>
            </Row>
           
          </Container>
          <Helmet>
          <meta charSet="utf-8" />
                <title>{t('title.emch')}</title>
          </Helmet>
        </div>
        <Portfolio/>
        <DarkFooter />
      </div>
    </>
  );
}

export default WhatEmch;
