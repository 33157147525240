import React from "react";
// reactstrap components
import { Button, Container, Row, Col, Card, CardText, CardBody, CardLink, CardImg,
  CardTitle, CardSubtitle } from "reactstrap";
import { useTranslation } from 'react-i18next'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHeader from "components/Headers/ContactHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Link } from "react-router-dom";

// core components

function Contact() {
  const {t,i18n} = useTranslation()

  return (
    <>
    <IndexNavbar/>
    <div className="wrapper">
    <ContactHeader/>
      <div className="section">
        <Container>
        <Card>
       
        <CardBody>
          <CardTitle className=" h2 mb-0">{t('hospital.contact')}</CardTitle>
          
          
          <CardText className=" mt-4">
          <CardSubtitle className="h4"> {t('contact.inquiry3')}</CardSubtitle>
          {t('contact.inquiry4')}
          <br></br>
          <b>{t('whatemch.email')}: </b>
            <big>emch@lanex.co.jp</big>
            <br></br>      
        <b>{t('contact.japan')}: </b>
        <big>+81 022-224-7625</big>
        <br></br>      
        <b>{t('contact.philippines')}: </b>
        <big>+81 022-224-7625</big>
        <br></br>      
        <b>{t('contact.senegal')}: </b>
        <big>+81 022-224-7625</big>
          </CardText>
        </CardBody>
      </Card>
        </Container>
      </div>
      <DarkFooter/>
      </div>
    </>
  );
}

export default Contact;
