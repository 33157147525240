import React from 'react'
import {
    Container,
    Row,
    Col
  } from "reactstrap";
import { useTranslation } from 'react-i18next'

function RequirementsDoctor() {
  const {t,i18n} = useTranslation()

    return (
        <div className="section section-contact-us text-center" id="requirements-doctor">
          <Container>
            <h2 className="title">{t('navbar.requirements')}</h2>
            <p className="description">{t('hospital.content4')}</p>
            <Row>
              <Col className="mb-4 text-center" md="6">
                <h4 className="title">{t('hospital.windows')}</h4>
                <b>OS:</b> {t('hospital.spec1')}
                <br/>
                <b>Browser:</b> {t('hospital.spec2')}
                <br/>
                

              </Col>
              <Col className="mb-4 text-center" md="6">
                <h4 className="title">{t('hospital.mac')}</h4>
                <b>OS:</b> {t('hospital.spec4')}
                <br/>
                <b>Browser:</b> {t('hospital.spec5')}
                <br/>
              </Col>
            </Row>
          </Container>
        </div>
    )
}

export default RequirementsDoctor
