import React from 'react';
import { Container, Row, Col} from "reactstrap";
import { useTranslation } from 'react-i18next'

function HowitWorksMother() {
  const {t,i18n} = useTranslation()

    return (
        <div className="section section-team text-center" id="works-mother">
          <Container>
            <h2 className="title">{t('navbar.how')}</h2>
            <div className="team">
              <Row>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/edu.png")}
                    ></img>
                    <h4 className="title">{t('mother.title1')}</h4>
                    <p className="description">
                    {t('mother.subtitle1')}
                    </p>
                    
                  </div>
                </Col>
                
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/services.png")}
                    ></img>
                    <h4 className="title">{t('mother.title2')}</h4>
                    <p className="description">
                    {t('mother.subtitle2')}                    </p>
                    
                  </div>
                </Col>
                
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/emb.png")}
                    ></img>
                    <h4 className="title">{t('mother.title3')}</h4>
                    <p className="description">
                    {t('mother.subtitle3')}                    </p>
                    
                  </div>
                
                
                </Col>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/reminder.png")}
                    ></img>
                    <h4 className="title">{t('mother.title4')}</h4>
                    <p className="description">
                    {t('mother.subtitle4')}                    </p>
                    
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    )
}

export default HowitWorksMother
