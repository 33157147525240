import React from 'react'
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next'


function FeaturesMother() {
  const {t,i18n} = useTranslation()

    return (
        <div className="features-4" id="features-mother">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">{t('navbar.features')}</h2>
              <h5 className="description">
              {t('mother.content2')}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="image-container">
                <img
                  alt="..."
                  src={require("assets/img/emch_phone2.png")}
                />
              </div>
              <div className="image-container">
                <img
                  alt="..."
                  src={require("assets/img/emch_desk2.png")}
                />
              </div>
            </Col>
            <Col className="offset-1" md="4">
              <div className="info info-horizontal">
                  
                <div className="icon icon-info">
                  <i aria-hidden={true} className="nc-icon nc-atom" />
                </div>
                <div className="description">
                    
                  <h4 className="info-title">{t('mother.feature1')}</h4>
                  <p>
                  {t('mother.subfeature1')}
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-success">
                  <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                </div>
                <div className="description">
                  <h4 className="info-title">{t('mother.feature2')}</h4>
                  <p>
                  {t('mother.subfeature2')}
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                </div>
                <div className="description">
                  <h4 className="info-title">{t('mother.feature3')}</h4>
                  <p>
                  {t('mother.subfeature3')}
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                </div>
                <div className="description">
                  <h4 className="info-title">{t('mother.feature4')}</h4>
                  <p>
                  {t('mother.subfeature4')}
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                </div>
                <div className="description">
                  <h4 className="info-title">{t('mother.feature5')}</h4>
                  <p>
                  {t('mother.subfeature5')}

                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                </div>
                <div className="description">
                  <h4 className="info-title">{t('mother.feature6')}</h4>
                  <p>
                  {t('mother.subfeature6')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default FeaturesMother
