import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {useTranslation} from "react-i18next";


function Portfolio() {
const [pills, setPills] = React.useState("1");
const { t, i18n } = useTranslation()

  return (
    <>
      <div className="profile-page">
        <div className="section">
          <Container>            
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <i className="now-ui-icons tech_mobile"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons tech_laptop"></i>
                      </NavLink>
                    </NavItem>
                    
                    
                  </Nav>
                </div>
              </Col>
              
              <TabContent className="gallery" activeTab={"pills" + pills}>
                
                <TabPane tabId="pills1">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="6">
                        <img
                          alt="..."
                          // className="img-raised"
                          src={require("assets/img/tp2.png")}
                        ></img>
                        <img
                          alt="..."
                          // className="img-raised"
                          src={require("assets/img/tp4.png")}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="..."
                          // className="img-raised"
                          src={require("assets/img/tp3.png")}
                        ></img>
                        <img
                          alt="..."
                          // className="img-raised"
                          src={require("assets/img/tp5.png")}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="pills2">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/tp8.png")}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/tp7.png")}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/tp9.png")}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/tp10.png")}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                
              </TabContent>
            </Row>
          </Container>
        </div>
        
      </div>
      </>
  );
}

export default Portfolio;
