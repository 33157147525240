
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import './i18next'


import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

import App from "./App";
import WhatEmch from "views/pages/WhatEmch.js";
import MotherPage from "views/pages/MotherPage";
import DoctorPage from "views/pages/DoctorPage";
import News from "views/pages/News";
import Ticad from "views/sections/articles/Ticad";
import Contact from "views/sections/contact/Contact";
import LanexKmc from "views/sections/articles/LanexKmc";
import Privacy from "views/sections/privacy/Privacy";
import LanexShiono from "views/sections/articles/LanexShiono"

const history = createHistory();


ReactDOM.render(
  <Suspense fallback={<loading/>}>
    <Router history={history}>
  {/* <HashRouter history={history}> */}
    <Switch>
      

        <Route path="/index" render={(props) => <App {...props} />} />
        <Route path="/news" render={(props) => <News {...props} />} />
        
        <Route path="/what-emch" render={(props) => <WhatEmch {...props} />} />
        <Route path="/mother-page" render={(props) => <MotherPage {...props} />} />
        <Route path="/doctor-page" render={(props) => <DoctorPage {...props} />}/>
        <Route path="/ticad" render={(props) => <Ticad {...props} />} />
        <Route path="/lanexkmc" render={(props) => <LanexKmc {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route path="/privacy" render={(props) => <Privacy {...props} />} />
        <Route path="/about_content" render={(props) => <LanexShiono {...props} />} />
       <Redirect to="/index" /> 
        <Route>
					<Redirect to='/' />
				</Route>
        <Redirect from="/" to="/index" /> 
        
    </Switch>
    </Router>
  {/* </HashRouter> */}
  </Suspense>,
  document.getElementById("root")
);
