/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a>
                LANEX CORPORATION
              </a>
            </li>
            <li>
              <a>
              <Link to="/contact">Contact Us</Link>
              </a>
            </li>
            <li>
              <a>
              <Link to="/privacy">EMCH Privacy Policy</Link>
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Created by{" "}
          <a
            href="https://www.lanex.co.jp/"
            target="_blank"
          >
            LANEX CORPORATION
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
